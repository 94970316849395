import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, catchError, delay, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { Settings } from './shared/models/settings.model';
import * as moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { Asset, EnrollAssets } from './shared/models/asset.model';
import { FilterService } from './shared/services/filter.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public scrollTop: BehaviorSubject<number> = new BehaviorSubject(0);
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    apiPath: string = environment.BACKEND_URL;
    appPath: string = environment.BACKEND_APP;
    settings: Settings;
    mappedAssets: any;
    lawAssets: Asset[]; 
    enrollAssets: EnrollAssets;
    isBrowser: boolean;

    sideMenuToggle: boolean = false;
    sideMenuItems: any[] = [
        {
            path: '/',
            title: 'Acasa',
            sub: null
        },
        {
            path: '/cursuri',
            title: 'Cursuri',
            sub: {
                active: false,
                items: [
                    { path: null, title: 'Cursuri Online' },
                    { path: null, title: 'Cursuri Locale' },
                    { path: '/lista-cursuri', title: 'Listă Cursuri' },
                ]
            }
        },
        {
            path: '/locatii',
            title: 'Locatii',
            sub: {
                active: false,
                items: [
                    { path: '/statiuni', title: 'Staiuni' },
                    { path: '/locatii', title: 'Locatii' },
                    { path: null, title: 'Ghid Hotelier' },
                ]
            }
        },
        {
            path: '/cine-suntem',
            title: 'Info',
            sub: {
                active: false,
                items: [
                    { path: '/cine-suntem', title: 'Cine Suntem' },
                    { path: '/lectori', title: 'Lectori' },
                    { path: '/gdpr', title: 'GDPR' },
                    { path: '/politica-cookie', title: 'Politica Cookie' },
                    { path: '/termeni-si-conditii', title: 'Termeni și Condiții ' }
                ]
            }
        },
        { path: '/contact', title: 'Contact', sub: null },
    ];

    private months = [
        { 'January': 'Ianuarie' },
        { 'February': 'Februarie' },
        { 'March': 'Martie' },
        { 'April': 'Aprilie' },
        { 'May': 'Mai' },
        { 'June': 'Iunie' },
        { 'July': 'Iulie' },
        { 'August': 'August' },
        { 'September': 'Septembrie' },
        { 'October': 'Octombrie' },
        { 'November': 'Noiembrie' },
        { 'December': 'Decembrie' }
    ];

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient,
        private fileSaverService: FileSaverService,
        private filterService: FilterService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    init() {
        return this.getSettings().pipe(
            map((result: any) => {
                return result.settings;
            }),
            catchError((error) => {
                console.error('APP INIT WARNING');
                //console.error('APP INIT ERROR: ', error);
                return of(null);
            })
        );

    }

    getSettings(): Observable<Settings> {
        return this.http.get(`${this.apiPath}/public/settings`).pipe(
            map((result: any) => {
                this.settings = result.settings;
                this.mappedAssets = result.mappedAssets;
                this.lawAssets = result.lawAssets;
                this.enrollAssets = result.enrollAssets;
                if (this.isBrowser) this.filterService.initBundleView();
                return result;
            }),
            catchError((error) => {
                console.log(error);
                return throwError(() => error);
            })
        );
    }

    downloadFile(path, name) {
        return this.http.get(`${this.appPath + path}`, {
            responseType: "blob"
        }).subscribe({
            next: (file: Blob) => {
                this.fileSaverService.save(file, name);
            },
            error: (error) => {
                console.log("Download Error:", error);
            }
        });
    }

    // HELPERS

    setLoading(value: boolean): void {
        setTimeout(() => {
            this.loading$.next(value);
        }, 0);
    }

    getFirstPhone() {
        if (!this.settings && !this.settings.contact && !this.settings.contact.phones && !this.settings.contact.phones.length) return '0234.512.139';
        return this.settings.contact.phones.filter(contact => contact.type === 'fix')[0].number;
    }


    roDate(date, type, short = false) {
        let day;
        let engMonthName;
        let year;

        if (type === 'dmy') {
            day = moment(date, 'DD-MM-YYYY').format('DD');
            engMonthName = moment(date, 'DD-MM-YYYY').format('MMMM');
            year = moment(date, 'DD-MM-YYYY').format('YYYY');
        }
        if (type === 'iso') {
            day = moment(date).format('DD');
            engMonthName = moment(date).format('MMMM');
            year = moment(date).format('YYYY');
        }

        let roMonthName = this.months.find(month => month[engMonthName])[engMonthName];

        return { date: short ? `${day} ${roMonthName.slice(0, 3)}` : `${day} ${roMonthName}`, year };
    }

}
