import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CoursesService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    apiPath: string = environment.BACKEND_URL;
    isBrowser: boolean;
    selectedCourse: any = null;
    selectedSession: any = null;
    selectedOffer: any = null;

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private http: HttpClient,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    getCourseByUrl(url: string) {
        return this.http.get(`${this.apiPath}/public/courses/course`, { params: { url } }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

    getCourseSessionsByUrl(url: string) {
        return this.http.get(`${this.apiPath}/public/courses/sessions`, { params: { url } }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

    getFollowingCourses() {
        return this.http.get(`${this.apiPath}/public/courses/following`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

    getSessionByUrl(url: string) {
        return this.http.get(`${this.apiPath}/public/sessions/session`, { params: { url } }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

    // HELPERS

    setSelectedCourse(course) {
        this.selectedCourse = course;
        this.saveLocalData('selected_course', course);
    }

    setSelectedSession(session) {
        this.selectedSession = session;
        this.saveLocalData('selected_session', session);
    }

    removeSelectedCourse() {
        this.selectedCourse = null;
        this.removeLocalData('selected_course');
    }

    removeSelectedSession() {
        this.selectedSession = null;
        this.removeLocalData('selected_session');
    }

    removeSelectedOffer() {
        this.selectedOffer = null;
        this.removeLocalData('selected_offer');
    }

    removeSelectedOptions() {
        this.removeSelectedCourse();
        this.removeSelectedSession();
        this.removeSelectedOffer()
    }

    saveLocalData(key, value) {
        if (this.isBrowser) localStorage.setItem(key, JSON.stringify(value));
    }

    getLocalData(key) {
        let data = null;
        if (this.isBrowser) data = localStorage.getItem(key);
        return JSON.parse(data);
    }

    removeLocalData(key) {
        if (this.isBrowser) localStorage.removeItem(key);
    }
}
