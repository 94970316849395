import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Course } from '../shared/models/course.model';
import { Resort } from '../shared/models/resort.model';

export interface FilterData {
    courses: Course[];
    resorts: Resort[];
    ranges: {
        range: string;
        start: string;
        end: string;
    }[];
}

@Injectable({
    providedIn: 'root'
})
export class SitemapService {
    apiPath: string = environment.BACKEND_APP;

    constructor(
        private http: HttpClient,
    ) { }

    getSitemap(): Observable<any> {
        return this.http.get(`${this.apiPath}/sitemap.xml`, { responseType: 'text' }).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                console.log(error.error.message);
                return throwError(() => error.error);
            })
        );
    }

}