import {
    HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Page } from '../shared/models/page.model';

@Injectable({
    providedIn: 'root',
})
export class PageService {
    apiPath: string = environment.BACKEND_URL;
    page: Page;
    pageSubject: Subject<Page> = new Subject();

    constructor(
        private http: HttpClient,
    ) { }

    getPageByUrl(url): Observable<Page> {
        return this.http.get(`${this.apiPath}/public/pages/${url}`).pipe(
            map((result: any) => {
                return result;
            }),
            catchError((error) => {
                return throwError(() => error.error);
            })
        );
    }

}
